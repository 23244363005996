<template>
  <div>
    <template
      v-if="
        (institution && !institution.kl_assessment) ||
        (user && user.groups.includes(7))
      "
    >
      <Page404></Page404>
    </template>
    <template v-else>
      <RubricPreset :allows_crud="allows_crud"></RubricPreset>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "RubricPresetView",
  components: {
    RubricPreset: () => import("./RubricPreset.vue"),
    Page404: () => import("@/components/public/Page404"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "evaluations2.add_rubricpreset"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
    }),
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
</style>